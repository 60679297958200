var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.noLabel
        ? _c("div", [
            _c(
              "span",
              {
                class: { "field-mandatory": _vm.mandatory },
                staticStyle: { "font-size": "0.85rem" },
              },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ])
        : _vm._e(),
      _c("ValidationProvider", {
        attrs: { name: _vm.label, rules: _vm.formFieldRules },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "v-text-field",
                  _vm._g(
                    _vm._b(
                      {
                        ref: _vm.name,
                        staticClass: "mt-2",
                        attrs: {
                          "aria-label": _vm.label,
                          disabled: _vm.formFieldDisabled,
                          readonly: "",
                          "error-messages": _vm.formFieldErrors(errors),
                          counter: !_vm.noCounter,
                          label: "",
                          dense: _vm.appConfig.forms.dense,
                          flat: _vm.appConfig.forms.flat,
                          solo: _vm.appConfig.forms.solo,
                          "solo-inverted": _vm.appConfig.forms.soloInverted,
                          filled: _vm.appConfig.forms.filled,
                          outlined: _vm.appConfig.forms.outlined,
                        },
                        scopedSlots: _vm._u(
                          [
                            !_vm.viewMode
                              ? {
                                  key: "prepend-inner",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g({}, on),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            on: {
                                                              click:
                                                                _vm.reloadItemsFromParent,
                                                            },
                                                          },
                                                          [_vm._v("mdi-reload")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Reload Status")])]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                            _vm.value2.documentStatus === undefined ||
                            _vm.value2.documentStatus === "" ||
                            _vm.value2.documentStatus === "NOT_CREATED"
                              ? {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g({}, on),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              depressed: "",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.createDocument,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-newspaper-plus"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Create New Linked Document"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g({}, on),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              depressed: "",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.viewDocument,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-open-in-new"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("View Linked Document"),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.value2.documentStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.value2, "documentStatus", $$v)
                          },
                          expression: "value2.documentStatus",
                        },
                      },
                      "v-text-field",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }