var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "toolbar-actions": _vm.toolbarActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
      on: {
        "toolbar-action": function ($event) {
          return _vm.toolbarMethod($event)
        },
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: {
                                "view-mode": _vm.viewMode,
                                "no-title": "",
                                "no-maximize": "",
                              },
                              model: {
                                value: _vm.formDataF,
                                callback: function ($$v) {
                                  _vm.formDataF = $$v
                                },
                                expression: "formDataF",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: _vm.ae$lng("Vessel Reference"),
                              "no-maximize": "",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Reference Year"),
                                        name: "serialReference.year",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["serialReference"][
                                            "year"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["serialReference"],
                                            "year",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['serialReference']['year']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Reference number"),
                                        name: "serialReference.number",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["serialReference"][
                                            "number"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["serialReference"],
                                            "number",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['serialReference']['number']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Reference node"),
                                        name: "serialReference.node",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["serialReference"][
                                            "node"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["serialReference"],
                                            "node",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['serialReference']['node']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: _vm.ae$lng("Voyage Information"),
                              "no-maximize": "",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Date of departure"),
                                        name: "dateOfDeparture",
                                      },
                                      model: {
                                        value: _vm.formData["dateOfDeparture"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "dateOfDeparture",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['dateOfDeparture']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Voyage number"),
                                        name: "voyageNumber",
                                      },
                                      model: {
                                        value: _vm.formDataF["voyageNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "voyageNumber",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['voyageNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    !_vm.viewMode
                                      ? _c("asyent-form-finder", {
                                          ref: _vm.name,
                                          attrs: {
                                            "working-date": _vm.workingDate,
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "service-name": "catalog",
                                            catalog: "CatalogPlaceOfLoading",
                                            "data-fields": _vm.dataFields,
                                            props: {},
                                            label: _vm.ae$lng(
                                              "Last Port of Call Code"
                                            ),
                                            "item-value": "code",
                                            name: "lastPortOfCallCode",
                                            "as-text": "",
                                            "is-not-cacheable": true,
                                            "pre-poplated": false,
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "lastPortOfCallCode"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "lastPortOfCallCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['lastPortOfCallCode']",
                                          },
                                        })
                                      : _c("asyent-form-display", {
                                          attrs: {
                                            value: [
                                              _vm.formDataF[
                                                "lastPortOfCallCode"
                                              ],
                                              _vm.formDataF[
                                                "lastPortOfCallCode"
                                              ],
                                            ],
                                            label: _vm.ae$lng(
                                              "Last Port of Call Code"
                                            ),
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    !_vm.viewMode
                                      ? _c("asyent-form-catalog", {
                                          attrs: {
                                            sortable: "",
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "service-name": "catalog",
                                            catalog: "CatalogCustomsOffice",
                                            props: props,
                                            "show-item-code": "",
                                            label: _vm.ae$lng("Office Code"),
                                            "item-value": "code",
                                            "item-text": "name",
                                            name: "officeCode",
                                          },
                                          model: {
                                            value: _vm.formDataF["officeCode"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "officeCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['officeCode']",
                                          },
                                        })
                                      : _c("asyent-form-display", {
                                          attrs: {
                                            value: [
                                              _vm.formDataF["officeCode"],
                                              _vm.formDataF["officeCode"],
                                            ],
                                            label: _vm.ae$lng("Office Code"),
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: _vm.ae$lng("Vessel Suffarence Request"),
                              expandable: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                !_vm.viewMode
                                  ? {
                                      key: "toolbar-actions",
                                      fn: function () {
                                        return [
                                          _c("asyent-button", {
                                            attrs: {
                                              "button-icon": "mdi-plus",
                                              "button-tooltip":
                                                _vm.ae$lng("Add Sufferance"),
                                              icon: _vm.$vuetify.breakpoint
                                                .smAndDown,
                                              text: !_vm.$vuetify.breakpoint
                                                .smAndDown,
                                            },
                                            on: {
                                              confirmed: function ($event) {
                                                return _vm.sufferances.push({})
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _vm.getEnv === "lk"
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          dense: "",
                                        },
                                      },
                                      [
                                        _vm._v("Director General of Customs "),
                                        _c("br"),
                                        _vm._v("Sri Lanka Customs "),
                                        _c("br"),
                                        _vm._v(" Colombo 01"),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(
                                          " General Request for Import Shipment"
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(
                                          " We hereby request from you to grant permission to discharge and store the goods which are declared in the ship's cargo manifest except (Arms, Ammunition, Dangrous Cargo that needs clearance from DDC) at the " +
                                            _vm._s(
                                              _vm.formDataF[
                                                "sufferanceTerminal"
                                              ] === null
                                                ? "........"
                                                : _vm.formDataF[
                                                    "sufferanceTerminal"
                                                  ]
                                            ) +
                                            " Terminal premises. We confirm that these goods will not be delivered until the required clearances are obtained from Customs and the duties are paid. Kindly grant permission for above vessel to be birthed at " +
                                            _vm._s(
                                              _vm.formDataF[
                                                "sufferanceTerminal"
                                              ] === null
                                                ? "........"
                                                : _vm.formDataF[
                                                    "sufferanceTerminal"
                                                  ]
                                            ) +
                                            "."
                                        ),
                                      ]
                                    )
                                  : _vm.getEnv === "kh"
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          dense: "",
                                        },
                                      },
                                      [
                                        _vm._v("Director General"),
                                        _c("br"),
                                        _vm._v(
                                          "General Department of Customs and Excise of Cambodia "
                                        ),
                                        _c("br"),
                                        _vm._v(" Phnom Penh"),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(
                                          " General Request for Import Shipment"
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(
                                          " We hereby request from you to grant permission to discharge and store the goods which are declared in the ship's cargo manifest except (Arms, Ammunition, Dangrous Cargo that needs clearance from DDC) at the " +
                                            _vm._s(
                                              _vm.formDataF[
                                                "sufferanceTerminal"
                                              ] === null
                                                ? "........"
                                                : _vm.formDataF[
                                                    "sufferanceTerminal"
                                                  ]
                                            ) +
                                            " Terminal premises. We confirm that these goods will not be delivered until the required clearances are obtained from Customs and the duties are paid. Kindly grant permission for above vessel to be birthed at " +
                                            _vm._s(
                                              _vm.formDataF[
                                                "sufferanceTerminal"
                                              ] === null
                                                ? "........"
                                                : _vm.formDataF[
                                                    "sufferanceTerminal"
                                                  ]
                                            ) +
                                            "."
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          dense: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " General Request for Import Shipment"
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(
                                          " We hereby request from you to grant permission to discharge and store the goods which are declared in the ship's cargo manifest except (Arms, Ammunition, Dangrous Cargo that needs clearance from DDC) at the " +
                                            _vm._s(
                                              _vm.formDataF[
                                                "sufferanceTerminal"
                                              ] === null
                                                ? "........"
                                                : _vm.formDataF[
                                                    "sufferanceTerminal"
                                                  ]
                                            ) +
                                            " Terminal premises. We confirm that these goods will not be delivered until the required clearances are obtained from Customs and the duties are paid. Kindly grant permission for above vessel to be birthed at " +
                                            _vm._s(
                                              _vm.formDataF[
                                                "sufferanceTerminal"
                                              ] === null
                                                ? "........"
                                                : _vm.formDataF[
                                                    "sufferanceTerminal"
                                                  ]
                                            ) +
                                            "."
                                        ),
                                      ]
                                    ),
                                _vm._l(_vm.sufferances, function (r, index) {
                                  return _c(
                                    "v-row",
                                    { key: _vm.sufferance(r)["id"] },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "asyent-card",
                                            {
                                              attrs: {
                                                title:
                                                  "Sufferance " + (index + 1),
                                                "toc-level": -1,
                                                "no-maximize": "",
                                                color: "cardDefault",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "asyent-form-catalog",
                                                        {
                                                          attrs: {
                                                            sortable: "",
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              function () {
                                                                return !_vm.sso.hasRoles(
                                                                  "portcall-request-sufferance"
                                                                )
                                                              },
                                                            "service-name":
                                                              "catalog",
                                                            catalog:
                                                              "CatalogBerth",
                                                            props: props,
                                                            "show-item-code":
                                                              "",
                                                            label: _vm.ae$lng(
                                                              "Sufferance Terminal"
                                                            ),
                                                            "item-value":
                                                              "code",
                                                            "item-text":
                                                              "description",
                                                            name: "sufferanceTerminal",
                                                          },
                                                          model: {
                                                            value:
                                                              r.sufferanceTerminal,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                r,
                                                                "sufferanceTerminal",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "r.sufferanceTerminal",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c("asyent-form-text", {
                                                        attrs: {
                                                          "view-mode":
                                                            _vm.viewMode,
                                                          "form-errors":
                                                            _vm.formErrors,
                                                          "form-rules":
                                                            _vm.formFieldsRules,
                                                          "is-disabled-func":
                                                            function () {
                                                              return true
                                                            },
                                                          props: props,
                                                          label: _vm.ae$lng(
                                                            "Sufferance Operator"
                                                          ),
                                                          name: "sufferanceOperator",
                                                        },
                                                        model: {
                                                          value:
                                                            r.sufferanceOperator,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              r,
                                                              "sufferanceOperator",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "r.sufferanceOperator",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "mt-5",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "2",
                                                      },
                                                    },
                                                    [
                                                      _vm.sso.hasRoles(
                                                        "portcall-request-sufferance"
                                                      ) && !_vm.viewMode
                                                        ? _c("asyent-button", {
                                                            attrs: {
                                                              "button-icon":
                                                                "mdi-plus",
                                                              "button-tooltip":
                                                                _vm.ae$lng(
                                                                  "Myself"
                                                                ),
                                                              icon: _vm.$vuetify
                                                                .breakpoint
                                                                .smAndDown,
                                                              text: !_vm
                                                                .$vuetify
                                                                .breakpoint
                                                                .smAndDown,
                                                            },
                                                            on: {
                                                              confirmed:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.setMyself(
                                                                    index
                                                                  )
                                                                },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c("asyent-form-select", {
                                                        attrs: {
                                                          "form-errors":
                                                            _vm.formErrors,
                                                          "form-rules":
                                                            _vm.formFieldsRules,
                                                          "is-disabled-func":
                                                            function () {
                                                              return !_vm.sso.hasRoles(
                                                                "portcall-grant-sufferance"
                                                              )
                                                            },
                                                          "items-reload":
                                                            _vm.approvalStatusReload,
                                                          props: props,
                                                          "drop-down": "",
                                                          label: _vm.ae$lng(
                                                            "Sufferance Approval"
                                                          ),
                                                          name: "sufferanceApproval",
                                                          sortable: "",
                                                          "view-mode":
                                                            _vm.viewMode,
                                                        },
                                                        model: {
                                                          value:
                                                            r.sufferanceApproval,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              r,
                                                              "sufferanceApproval",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "r.sufferanceApproval",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "1",
                                                      },
                                                    },
                                                    [
                                                      !_vm.viewMode
                                                        ? _c("asyent-button", {
                                                            attrs: {
                                                              "button-icon":
                                                                "mdi-delete",
                                                              "button-tooltip":
                                                                _vm.ae$lng(
                                                                  "Delete Sufferance"
                                                                ),
                                                              icon: _vm.$vuetify
                                                                .breakpoint
                                                                .smAndDown,
                                                              text: !_vm
                                                                .$vuetify
                                                                .breakpoint
                                                                .smAndDown,
                                                              "requires-confirmation":
                                                                "",
                                                            },
                                                            on: {
                                                              confirmed:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.sufferances.splice(
                                                                    index,
                                                                    1
                                                                  )
                                                                },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: _vm.ae$lng("Arrival"),
                              expandable: "",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Arrival date time"),
                                        type: "datetime-local",
                                        name: "arrivalDateTime",
                                      },
                                      model: {
                                        value: _vm.formData["arrivalDateTime"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "arrivalDateTime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['arrivalDateTime']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Vessel Name"),
                                        name: "vesselName",
                                      },
                                      model: {
                                        value: _vm.formDataF["vesselName"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "vesselName",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['vesselName']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    !_vm.viewMode
                                      ? _c("asyent-form-catalog", {
                                          attrs: {
                                            sortable: "",
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "service-name": "catalog",
                                            catalog: "CatalogConnectIdAgency",
                                            props: props,
                                            "show-item-code": "",
                                            label: _vm.ae$lng("Carrier Code"),
                                            "item-value": "code",
                                            "item-text": "name",
                                            name: "carrierCode",
                                          },
                                          model: {
                                            value: _vm.formDataF["carrierCode"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "carrierCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['carrierCode']",
                                          },
                                        })
                                      : _c("asyent-form-display", {
                                          attrs: {
                                            value: [
                                              _vm.formDataF["carrierCode"],
                                              _vm.formDataF["carrierCode"],
                                            ],
                                            label: _vm.ae$lng("Carrier Code"),
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Total gross mass"),
                                        name: "totalGrossMass",
                                      },
                                      model: {
                                        value: _vm.formDataF["totalGrossMass"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "totalGrossMass",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['totalGrossMass']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng(
                                          "Total import containers"
                                        ),
                                        name: "totalImportContainers",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF[
                                            "totalImportContainers"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "totalImportContainers",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['totalImportContainers']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng(
                                          "Total Trashipment containers"
                                        ),
                                        name: "totalTranshipmentContainers",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF[
                                            "totalTranshipmentContainers"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "totalTranshipmentContainers",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['totalTranshipmentContainers']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        !_vm.isDisabled("actualBerthingDateTime") ||
                        _vm.viewMode
                          ? _c(
                              "asyent-card",
                              {
                                attrs: {
                                  title: _vm.ae$lng("Inspection"),
                                  expandable: "",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c("asyent-form-date", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng(
                                              "Actual Berthing Date Time"
                                            ),
                                            type: "datetime-local",
                                            name: "actualBerthingDateTime",
                                          },
                                          model: {
                                            value:
                                              _vm.formData[
                                                "actualBerthingDateTime"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "actualBerthingDateTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData['actualBerthingDateTime']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        !_vm.viewMode
                                          ? _c("asyent-form-catalog", {
                                              attrs: {
                                                sortable: "",
                                                "view-mode": _vm.viewMode,
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "service-name": "catalog",
                                                catalog: "CatalogBerth",
                                                props: props,
                                                "show-item-code": "",
                                                label:
                                                  _vm.ae$lng("Terminal Code"),
                                                "item-value": "code",
                                                "item-text": "description",
                                                name: "terminalCode",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["terminalCode"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "terminalCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['terminalCode']",
                                              },
                                            })
                                          : _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["terminalCode"],
                                                  _vm.formDataF["terminalCode"],
                                                ],
                                                label:
                                                  _vm.ae$lng("Terminal Code"),
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        !_vm.viewMode
                                          ? _c("asyent-form-catalog", {
                                              attrs: {
                                                sortable: "",
                                                "view-mode": _vm.viewMode,
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "service-name": "catalog",
                                                catalog: "CatalogVesselType",
                                                props: props,
                                                "show-item-code": "",
                                                label:
                                                  _vm.ae$lng(
                                                    "Vessel Type Code"
                                                  ),
                                                "item-value": "code",
                                                "item-text": "description",
                                                name: "vesselTypeCode",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF[
                                                    "vesselTypeCode"
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "vesselTypeCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['vesselTypeCode']",
                                              },
                                            })
                                          : _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF[
                                                    "vesselTypeCode"
                                                  ],
                                                  _vm.formDataF[
                                                    "vesselTypeCode"
                                                  ],
                                                ],
                                                label:
                                                  _vm.ae$lng(
                                                    "Vessel Type Code"
                                                  ),
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        !_vm.viewMode
                                          ? _c("asyent-form-catalog", {
                                              attrs: {
                                                sortable: "",
                                                "view-mode": _vm.viewMode,
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "service-name": "catalog",
                                                catalog:
                                                  "CatalogConnectIdCountry",
                                                props: props,
                                                "show-item-code": "",
                                                label: _vm.ae$lng("Flag Code"),
                                                "item-value": "code",
                                                "item-text": "name",
                                                name: "flagCode",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["flagCode"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "flagCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['flagCode']",
                                              },
                                            })
                                          : _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["flagCode"],
                                                  _vm.formDataF["flagCode"],
                                                ],
                                                label: _vm.ae$lng("Flag Code"),
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng("IMO number"),
                                            name: "imoNumber",
                                          },
                                          model: {
                                            value: _vm.formDataF["imoNumber"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "imoNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['imoNumber']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label:
                                              _vm.ae$lng("Bond Seal Numbers"),
                                            name: "bondSealNumbers",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["bondSealNumbers"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "bondSealNumbers",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['bondSealNumbers']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng(
                                              "Bond Store Deposit Numbers"
                                            ),
                                            name: "bondStoreDepositNumbers",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "bondStoreDepositNumbers"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "bondStoreDepositNumbers",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['bondStoreDepositNumbers']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "12" } },
                                      [
                                        _c(
                                          "asyent-card",
                                          {
                                            attrs: {
                                              title: _vm.ae$lng("Documents"),
                                              "border-color": 2,
                                              "with-border": "",
                                              "no-maximize": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Last Port Clearance Available"
                                                        ),
                                                        name: "lastPortClearanceAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "lastPortClearanceAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "lastPortClearanceAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['lastPortClearanceAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Customs Six Available"
                                                        ),
                                                        name: "customsSixAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "customsSixAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "customsSixAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['customsSixAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "IMO General Declaration Available"
                                                        ),
                                                        name: "imoGeneralDeclarationAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "imoGeneralDeclarationAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "imoGeneralDeclarationAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['imoGeneralDeclarationAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "IMO Cargo Declaration Available"
                                                        ),
                                                        name: "imoCargoDeclarationAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "imoCargoDeclarationAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "imoCargoDeclarationAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['imoCargoDeclarationAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Crew List Available"
                                                        ),
                                                        name: "crewListAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "crewListAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "crewListAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['crewListAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Crew Effects Declaration Available"
                                                        ),
                                                        name: "crewEffectsDeclarationAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "crewEffectsDeclarationAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "crewEffectsDeclarationAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['crewEffectsDeclarationAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("linkedDocument", {
                                                      attrs: {
                                                        "key-meta": [
                                                          _vm.formDataF[
                                                            "dateOfDeparture"
                                                          ],
                                                          _vm.formDataF[
                                                            "voyageNumber"
                                                          ],
                                                          _vm.formDataF[
                                                            "lastPortOfCallCode"
                                                          ],
                                                        ],
                                                        "service-name": "cargo",
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.passengersStatusReload,
                                                        "create-uri":
                                                          "/cargo/passengers/new",
                                                        "view-uri":
                                                          "/cargo/passengers/view",
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "passengerListAvailable"
                                                        ),
                                                        name: "passengerListAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "passengerListAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "passengerListAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['passengerListAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Currency List Available"
                                                        ),
                                                        name: "currencyListAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "currencyListAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "currencyListAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['currencyListAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("linkedDocument", {
                                                      attrs: {
                                                        "key-meta": [
                                                          _vm.formDataF[
                                                            "dateOfDeparture"
                                                          ],
                                                          _vm.formDataF[
                                                            "voyageNumber"
                                                          ],
                                                          _vm.formDataF[
                                                            "lastPortOfCallCode"
                                                          ],
                                                        ],
                                                        "service-name": "cargo",
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.lastTenPortsStatusReload,
                                                        "create-uri":
                                                          "/cargo/lasttenports/new",
                                                        "view-uri":
                                                          "/cargo/lasttenports/view",
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "lastTenPortsAvailable"
                                                        ),
                                                        name: "lastTenPortsAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "lastTenPortsAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "lastTenPortsAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['lastTenPortsAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("linkedDocument", {
                                                      attrs: {
                                                        "key-meta": [
                                                          _vm.formDataF[
                                                            "dateOfDeparture"
                                                          ],
                                                          _vm.formDataF[
                                                            "voyageNumber"
                                                          ],
                                                          _vm.formDataF[
                                                            "lastPortOfCallCode"
                                                          ],
                                                        ],
                                                        "service-name": "cargo",
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.shipStoresStatusReload,
                                                        "create-uri":
                                                          "/cargo/shipstores/new",
                                                        "view-uri":
                                                          "/cargo/shipstores/view",
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Ship Store Declaration Available"
                                                        ),
                                                        name: "shipStoreDeclarationAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "shipStoreDeclarationAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "shipStoreDeclarationAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['shipStoreDeclarationAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label:
                                                          _vm.ae$lng(
                                                            "Nil List Available"
                                                          ),
                                                        name: "nilListAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "nilListAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "nilListAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['nilListAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Narcotics List Available"
                                                        ),
                                                        name: "narcoticsListAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "narcoticsListAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "narcoticsListAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['narcoticsListAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-select", {
                                                      attrs: {
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        "items-reload":
                                                          _vm.vesselDocumentStatusReload,
                                                        props: props,
                                                        "drop-down": "",
                                                        label: _vm.ae$lng(
                                                          "Load Discharge List Available"
                                                        ),
                                                        name: "loadDischargeListAvailable",
                                                        sortable: "",
                                                        "view-mode":
                                                          _vm.viewMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "loadDischargeListAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "loadDischargeListAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['loadDischargeListAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "9",
                                                    },
                                                  },
                                                  [
                                                    _c("asyent-form-text", {
                                                      attrs: {
                                                        "view-mode":
                                                          _vm.viewMode,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.isDisabled,
                                                        props: props,
                                                        label: _vm.ae$lng(
                                                          "Additional Documents Available"
                                                        ),
                                                        name: "additionalDocumentsAvailable",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formDataF[
                                                            "additionalDocumentsAvailable"
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formDataF,
                                                            "additionalDocumentsAvailable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formDataF['additionalDocumentsAvailable']",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-row"),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        !_vm.isDisabled("otRegistrationNumber") || _vm.viewMode
                          ? _c(
                              "asyent-card",
                              {
                                attrs: {
                                  title: _vm.ae$lng("Clearance Request"),
                                  expandable: "",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng(
                                              "OT Registration Number"
                                            ),
                                            name: "otRegistrationNumber",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "otRegistrationNumber"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "otRegistrationNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['otRegistrationNumber']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng("OT Amount"),
                                            name: "otAmount",
                                          },
                                          model: {
                                            value: _vm.formDataF["otAmount"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "otAmount",
                                                $$v
                                              )
                                            },
                                            expression: "formDataF['otAmount']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng("nameOfMaster"),
                                            name: "nameOfMaster",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["nameOfMaster"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "nameOfMaster",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['nameOfMaster']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        !_vm.viewMode
                                          ? _c("asyent-form-finder", {
                                              ref: _vm.name,
                                              attrs: {
                                                "working-date": _vm.workingDate,
                                                "view-mode": _vm.viewMode,
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "service-name": "catalog",
                                                catalog:
                                                  "CatalogPlaceOfLoading",
                                                "data-fields": _vm.dataFields,
                                                props: {},
                                                label: _vm.ae$lng(
                                                  "Departure Port Code"
                                                ),
                                                "item-value": "code",
                                                name: "description",
                                                "as-text": "",
                                                "is-not-cacheable": true,
                                                "pre-poplated": false,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF[
                                                    "departurePortCode"
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "departurePortCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['departurePortCode']",
                                              },
                                            })
                                          : _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF[
                                                    "departurePortCode"
                                                  ],
                                                  _vm.formDataF[
                                                    "departurePortCode"
                                                  ],
                                                ],
                                                label: _vm.ae$lng(
                                                  "Departure Port Code"
                                                ),
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng(
                                              "Estimate Departure Date Time"
                                            ),
                                            type: "datetime-local",
                                            name: "estimateDepartureDateTime",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "estimateDepartureDateTime"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "estimateDepartureDateTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['estimateDepartureDateTime']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        !_vm.viewMode
                                          ? _c("asyent-form-finder", {
                                              ref: _vm.name,
                                              attrs: {
                                                "working-date": _vm.workingDate,
                                                "view-mode": _vm.viewMode,
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "service-name": "catalog",
                                                catalog:
                                                  "CatalogPlaceOfLoading",
                                                "data-fields": _vm.dataFields,
                                                props: {},
                                                label:
                                                  _vm.ae$lng("Next Port Code"),
                                                "item-value": "code",
                                                name: "description",
                                                "as-text": "",
                                                "is-not-cacheable": true,
                                                "pre-poplated": false,
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["nextPortCode"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "nextPortCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['nextPortCode']",
                                              },
                                            })
                                          : _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["nextPortCode"],
                                                  _vm.formDataF["nextPortCode"],
                                                ],
                                                label:
                                                  _vm.ae$lng("Next Port Code"),
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng(
                                              "Departure Voyage Number"
                                            ),
                                            name: "departureVoyageNumber",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "departureVoyageNumber"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "departureVoyageNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['departureVoyageNumber']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng("Gross Tonnage"),
                                            name: "grossTonnage",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["grossTonnage"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "grossTonnage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['grossTonnage']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng("Net Tonnage"),
                                            name: "netTonnage",
                                          },
                                          model: {
                                            value: _vm.formDataF["netTonnage"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "netTonnage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['netTonnage']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng(
                                              "Number of Passengers"
                                            ),
                                            name: "numberOfPassengers",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "numberOfPassengers"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "numberOfPassengers",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['numberOfPassengers']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng("Number of Crew"),
                                            name: "numberOfCrew",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["numberOfCrew"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "numberOfCrew",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['numberOfCrew']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        !_vm.isDisabled("inwardManifestAvailable") ||
                        _vm.viewMode
                          ? _c(
                              "asyent-card",
                              {
                                attrs: {
                                  title: _vm.ae$lng("Clearance"),
                                  expandable: "",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.vesselDocumentStatusReload,
                                            props: props,
                                            "drop-down": "",
                                            label: _vm.ae$lng(
                                              "Inward Manifest Available"
                                            ),
                                            name: "inwardManifestAvailable",
                                            sortable: "",
                                            "view-mode": _vm.viewMode,
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "inwardManifestAvailable"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "inwardManifestAvailable",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['inwardManifestAvailable']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.vesselDocumentStatusReload,
                                            props: props,
                                            "drop-down": "",
                                            label: _vm.ae$lng(
                                              "Outward Manifest Available"
                                            ),
                                            name: "outwardManifestAvailable",
                                            sortable: "",
                                            "view-mode": _vm.viewMode,
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "outwardManifestAvailable"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "outwardManifestAvailable",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['outwardManifestAvailable']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("asyent-form-select", {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple",
                                              value: false,
                                              expression: "false",
                                            },
                                          ],
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.vesselDocumentStatusReload,
                                            props: props,
                                            "drop-down": "",
                                            label: _vm.ae$lng(
                                              "OT Register Application Available"
                                            ),
                                            name: "otRegisterApplicationAvailable",
                                            sortable: "",
                                            "view-mode": _vm.viewMode,
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "otRegisterApplicationAvailable"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "otRegisterApplicationAvailable",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['otRegisterApplicationAvailable']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        !_vm.isDisabled("detainComment") || _vm.viewMode
                          ? _c(
                              "asyent-card",
                              {
                                attrs: {
                                  title: _vm.ae$lng("Detain"),
                                  expandable: "",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "12" } },
                                      [
                                        _c("asyent-form-textarea", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: _vm.ae$lng("Detain Comment"),
                                            name: "detainComment",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["detainComment"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "detainComment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['detainComment']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }